const rootUrl = `${window.location.protocol}//${window.location.host}`;

interface IEnvironment {
	API_URL: string;
	API_PORT?: number;
	AUTH_URL: string;
	AUTH_PORT?: number;
	STRIPE_KEY: string;
	MIXPANEL_KEY: string;
}

const localEnv: IEnvironment = {
	API_URL: "https://localhost",
	API_PORT: 5000,
	AUTH_URL: "https://localhost",
	AUTH_PORT: 5000,
	STRIPE_KEY: "pk_test_pRA1OtcniCdvzzzMGK5vyYa9",
	MIXPANEL_KEY: "ddf3cdde1843dc5c0153cd0a487441da"
};

const testEnv: IEnvironment = {
	API_URL: "https://api.gethair.codenite.at",
	AUTH_URL: "https://api.gethair.codenite.at",
	STRIPE_KEY: "pk_test_pRA1OtcniCdvzzzMGK5vyYa9",
	MIXPANEL_KEY: "ddf3cdde1843dc5c0153cd0a487441da"
};

const loadTestEnv: IEnvironment = {
	API_URL: "https://api.gethair-lt.codenite.at",
	AUTH_URL: "https://api.gethair-lt.codenite.at",
	STRIPE_KEY: "pk_test_pRA1OtcniCdvzzzMGK5vyYa9",
	MIXPANEL_KEY: "ddf3cdde1843dc5c0153cd0a487441da"
};

const prodEnv: IEnvironment = {
	API_URL: "https://api.gethair.me",
	AUTH_URL: "https://api.gethair.me",
	STRIPE_KEY: "pk_live_lXBvX4EkXpdmVD9AlvUgQAzN",
	MIXPANEL_KEY: "23d9d6b698bd6b87e67674e52302fb94"
};

const envs: any = {
	"http://localhost:3000": localEnv,
	"https://checkout.gethair.codenite.at": testEnv,
	"https://www.checkout.gethair.codenite.at": testEnv,
	"https://checkout.gethair-lt.codenite.at": loadTestEnv,
	"https://www.checkout.gethair-lt.codenite.at": loadTestEnv,
	"https://checkout.gethair.me": prodEnv,
	"https://www.checkout.gethair.me": prodEnv
};

let currentEnv = prodEnv;

if (envs[rootUrl]) {
	currentEnv = envs[rootUrl];
}

export default currentEnv;
