import React, { useState } from "react";
import isEmail from "isemail";
import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { AxiosResponse } from "axios";
import { checkVAT, countries as vatCountries } from "jsvat";
import queryString from "query-string";

import axios from "../../axios";
import Wizard from "../wizard";
import ErrorModal from "../error-modal";
import MailCheckoutForm from "./pages/mail";
import PayCheckoutForm from "./pages/pay";
import InvoiceData from "./pages/invoice-data";

import { IBundle } from "../../dto/bundle";
import { IError } from "../../dto/error";
import { IFormData } from "../../dto/form";
import I18n from "../../utils/i18n";
import { getLocation, includesVat } from "../../utils/utils";
import { PaymentMethod } from "../../dto/payment";
import { trackPaymentFailed, trackPaymentStarted } from "../../utils/mixpanel";

interface ICheckoutFormProps {
	bundle: IBundle;
	quantity?: number;
	stripeCountries: string[];
	language?: string;
	mail?: string;
	onPaymentSuccessful: (successData: IPaymentSuccessData) => void;
}

export interface IPaymentSuccessData {
	mail: string;
	bundle: IBundle;
	quantity: number;
	includesVat: boolean;
	paymentIntentId?: string;
	paymentIntentSecret: string;
}

const CheckoutForm = (props: ICheckoutFormProps) => {
	const { bundle, quantity, stripeCountries, language, mail, onPaymentSuccessful } = props;

	const [paymentError, setPaymentError] = useState<string | undefined>();
	const stripe = useStripe();
	const elements = useElements();

	const validateCheckout = (values: Partial<IFormData>) => {
		const errors: any = {};

		if (!values.mail || values.mail.trim() === "") {
			errors.mail = I18n.t("mailPage.validation.mailRequired");
		} else if (!isEmail.validate(values.mail.trim())) {
			errors.mail = I18n.t("mailPage.validation.mailInvalid");
		}

		if (!values.agbAccepted) {
			errors.agbAccepted = I18n.t("mailPage.validation.tosRequired");
		}

		return errors;
	};

	const validateInvoiceData = (values: Partial<IFormData>) => {
		const errors: any = {};

		if (values.name == null || values.name.trim() === "") {
			errors.name = I18n.t("general.required");
		}

		if (values.street == null || values.street.trim() === "") {
			errors.street = I18n.t("general.required");
		}

		if (values.streetNumber == null || values.streetNumber.trim() === "") {
			errors.streetNumber = I18n.t("general.required");
		}

		if (values.postalCode == null || values.postalCode.trim() === "") {
			errors.postalCode = I18n.t("general.required");
		}

		if (values.city == null || values.city.trim() === "") {
			errors.city = I18n.t("general.required");
		}

		if (values.country == null) {
			errors.country = I18n.t("general.required");
		}

		if (values.taxId != null && values.country != null) {
			const country = vatCountries.find((x) => x.codes.includes(values.country!.value));

			if (country) {
				const result = checkVAT(values.taxId, [country]);

				if (!result.isValid) {
					errors.taxId = I18n.t("invoiceDataPage.validation.uidInvalid");
				}
			}
		}

		return errors;
	};

	const validatePayment = (values: Partial<IFormData>) => {
		const errors: any = {};

		if (values.paymentMethod == null) {
			errors.paymentMethod = I18n.t("paymentPage.validation.paymentMethodRequired");
		}

		if (!values.cardHolder || values.cardHolder.trim() === "") {
			errors.cardHolder = I18n.t("paymentPage.validation.cardHolderRequired");
		}

		if (values.paymentMethod === PaymentMethod.CreditCard) {
			if (!values.cardValid) {
				errors.cardValid = I18n.t("paymentPage.validation.cardNumberInvalid");
			}

			if (!values.cardExpiryValid) {
				errors.cardExpiryValid = I18n.t("paymentPage.validation.invalid");
			}

			if (!values.cardCVCValid) {
				errors.cardCVCValid = I18n.t("paymentPage.validation.invalid");
			}
		}

		return errors;
	};

	const handlePaymentError = (error: StripeError, method: "card" | "paypal") => {
		switch (error.code) {
			case "incorrect_cvc":
				setPaymentError(I18n.t("paymentPage.errors.cvcInvalid"));
				break;
			case "card_declined":
				setPaymentError(I18n.t("paymentPage.errors.cardDeclined"));
				break;
			case "expired_card":
				setPaymentError(I18n.t("paymentPage.errors.cardExpired"));
				break;
			default:
				setPaymentError(I18n.t("general.error"));
		}

		trackPaymentFailed(bundle.id, method, error.code);
	};

	const handleCreditCardPayment = async (secret: string, values: IFormData) => {
		if (!stripe || !elements) {
			return;
		}

		const cardElement = elements.getElement(CardNumberElement);

		if (!cardElement) {
			return;
		}

		try {
			const { error, paymentIntent } = await stripe.confirmCardPayment(secret, {
				payment_method: {
					card: cardElement,
					billing_details: {
						name: values.cardHolder,
						email: values.mail
					}
				}
			});

			if (error) {
				console.log(error);
				handlePaymentError(error, "card");
			} else {
				onPaymentSuccessful({
					mail: values.mail,
					bundle,
					quantity: quantity || 1,
					includesVat: includesVat(values),
					paymentIntentId: paymentIntent?.id,
					paymentIntentSecret: secret
				});
			}
		} catch (ex) {
			console.error(ex);
			setPaymentError(I18n.t("general.error"));
		}
	};

	const handlePayPalPayment = async (secret: string, values: IFormData) => {
		if (!stripe || !elements) {
			return;
		}

		const urlParams = {
			mail: values.mail,
			bundleId: bundle.id,
			includesVat: String(includesVat(values)),
			quantity: String(quantity),
			lang: language
		};

		const returnUrl = queryString.stringifyUrl({
			url: `${getLocation()}/complete`,
			query: urlParams
		});

		try {
			const { error } = await stripe.confirmPayPalPayment(secret, {
				payment_method: {
					billing_details: {
						address: {
							city: values.city,
							country: values.country.value,
							line1: `${values.street} ${values.streetNumber}`,
							postal_code: values.postalCode
						},
						name: values.name,
						email: values.mail
					}
				},
				return_url: returnUrl
			});

			if (error) {
				handlePaymentError(error, "paypal");
				console.log(error);
			}
		} catch (ex) {
			console.error(ex);
			setPaymentError(I18n.t("general.error"));
		}
	};

	const handleSubmit = async (values: IFormData) => {
		if (!stripe || !elements) {
			return;
		}

		if (values.mail) {
			values.mail = values.mail.trim();
		}

		const intentObject = {
			bundleId: bundle.id,
			mail: values.mail,
			cardHolder: values.cardHolder,
			quantity: quantity || 1,
			language: I18n.locale,
			newsletter: values.subscribe,
			name: values.name,
			street: values.street,
			streetNumber: values.streetNumber,
			postalCode: values.postalCode,
			city: values.city,
			country: values.country.value,
			taxId: values.taxId
		};

		let secretResponse: AxiosResponse<string> | undefined = undefined;

		trackPaymentStarted(bundle.id, quantity || 1);

		try {
			secretResponse = await axios.post<string>("/stripe/create-payment-intent", intentObject);
		} catch (ex) {
			const error = ex.response.data as IError;

			if (error.stripeCode === "tax_id_invalid") {
				setPaymentError(I18n.t("paymentPage.errors.uidInvalid"));
			} else {
				setPaymentError(I18n.t("general.error"));
			}

			return;
		}

		switch (values.paymentMethod) {
			case PaymentMethod.CreditCard:
				await handleCreditCardPayment(secretResponse.data, values);
				break;
			case PaymentMethod.PayPal:
				await handlePayPalPayment(secretResponse.data, values);
				break;
		}
	};

	return (
		<>
			<Wizard<IFormData>
				showFormButtons
				onSubmit={handleSubmit}
				payButtonDisabled={quantity == null}
				initialValues={{
					mail
				}}
			>
				<Wizard.Page validate={validateCheckout}>
					<MailCheckoutForm quantity={quantity} bundle={bundle} />
				</Wizard.Page>
				<Wizard.Page validate={validateInvoiceData}>
					<InvoiceData stripeCountries={stripeCountries} />
				</Wizard.Page>
				<Wizard.Page validate={validatePayment}>
					<PayCheckoutForm quantity={quantity} bundle={bundle} />
				</Wizard.Page>
			</Wizard>
			<ErrorModal
				isOpen={paymentError != null}
				title="Error"
				errorMessage={paymentError || ""}
				onClose={() => {
					setPaymentError(undefined);
				}}
			/>
		</>
	);
};

export default CheckoutForm;
