import React from "react";
import { Field } from "react-final-form";
import classNames from "classnames";

import { ReactComponent as CreditCardIcon } from "../../../assets/icons/credit-card.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as PaypalIcon } from "../../../assets/icons/paypal.svg";

import styles from "./styles.module.scss";
import { PaymentMethod } from "../../../dto/payment";
import I18n from "../../../utils/i18n";
import { IFormData } from "../../../dto/form";

interface IPaymentMethodSelectProps {
	values: IFormData;
}

const PaymentMethodSelect = (props: IPaymentMethodSelectProps) => {
	const { values } = props;

	const paymentMethods = [
		{
			name: I18n.t("general.paymentMethods.creditCard"),
			value: PaymentMethod.CreditCard,
			renderIcon: () => <CreditCardIcon />
		},
		{
			name: "PayPal",
			value: PaymentMethod.PayPal,
			renderIcon: () => <PaypalIcon />,
			allowedCountries: ["AT", "DE", "BE", "IT", "NL", "ES"]
		}
	];

	return (
		<Field name="paymentMethod">
			{({ input, meta }) => {
				const showError = meta.error && meta.touched;

				return (
					<>
						<div className={styles.paymentMethodContainer}>
							{paymentMethods.map((paymentMethod) => {
								const selected = input.value === paymentMethod.value;

								if (
									paymentMethod.allowedCountries &&
									paymentMethod.allowedCountries.length > 0 &&
									!paymentMethod.allowedCountries.includes(values.country.value)
								) {
									return null;
								}

								return (
									<div
										key={paymentMethod.name}
										className={classNames(styles.paymentMethod, {
											[styles.selected]: selected
										})}
										onClick={() => input.onChange(paymentMethod.value)}
									>
										<div className={styles.paymentMethodIcon}>{paymentMethod.renderIcon()}</div>
										<span>{paymentMethod.name}</span>

										<div
											className={classNames(styles.paymentSelectedIcon, {
												[styles.selected]: selected
											})}
										>
											<CheckIcon />
										</div>
									</div>
								);
							})}
						</div>
						{showError && (
							<div className="error" style={{ marginTop: "5px", textAlign: "center" }}>
								{meta.error}
							</div>
						)}
					</>
				);
			}}
		</Field>
	);
};

export default PaymentMethodSelect;
