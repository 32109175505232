import React from "react";
import classNames from "classnames";
import { useFormState } from "react-final-form";

import styles from "./styles.module.scss";
import TotalPrice from "../../../total-price";
import { IBundle } from "../../../../dto/bundle";
import I18n from "../../../../utils/i18n";
import { includesVat, getDiscountedNetPrice } from "../../../../utils/utils";
import { IFormData } from "../../../../dto/form";
import PaymentMethodSelect from "../../payment-method";
import CreditCardForm from "../../credit-card-form";
import { PaymentMethod } from "../../../../dto/payment";
import SofortForm from "../../sofort-form";

interface IPayCheckoutFormProps {
	quantity?: number;
	bundle: IBundle;
}

const PayCheckoutForm = (props: IPayCheckoutFormProps) => {
	const { quantity, bundle } = props;
	const { values } = useFormState<IFormData>();

	const renderPaymentForm = () => {
		switch (values.paymentMethod) {
			case PaymentMethod.CreditCard:
				return <CreditCardForm />;
			case PaymentMethod.PayPal:
				return <SofortForm />;
			default:
				return null;
		}
	};

	return (
		<div className={styles.formContainer}>
			<div className={styles.formHeader}>
				<h3>{I18n.t("general.payment")}</h3>
			</div>
			<div className={styles.formDataContainer}>
				<h4 style={{ textAlign: "center" }}>{I18n.t("general.paymentMethod")}</h4>

				<div className="mb-medium">
					<PaymentMethodSelect values={values} />
				</div>

				{renderPaymentForm()}
			</div>
			<div className={classNames(styles.formDataPadding, "mt-small", "mb-large")}>
				<TotalPrice includesVat={includesVat(values)} totalPrice={getDiscountedNetPrice(bundle, quantity || 1)} />
			</div>
		</div>
	);
};

export default PayCheckoutForm;
